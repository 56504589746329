















































































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { UserInfo, OrgAccount } from "@/types";
import { formatCurrency } from "@/utils/common";
import { CustomTable, AccountDetails } from "@components";

const userModule = namespace("user");
const orgModule = namespace("org");

interface State {
  showList: boolean;
  selectedAccount: null | OrgAccount;
}

@Component({
  components: {
    CustomTable,
    AccountDetails,
  },
})
export default class Accounts extends Vue {
  @userModule.Getter("userInfoExists") userInfoExists!: boolean;
  @userModule.State("userInfo") user!: UserInfo;
  @orgModule.State("accounts") accounts!: Array<OrgAccount>;

  get COLUMNS() {
    return [
      {
        field: "type",
        label: "Account Type",
        sortable: false,
      },
      {
        field: "accountNumber",
        label: "Account Number",
        sortable: false,
      },
      {
        field: "fundsInClearing",
        label: "Funds in Clearing",
        sortable: false,
      },
      {
        field: "currentBalance",
        label: "Current Balance",
        sortable: false,
      },
      {
        field: "availableBalance",
        label: "Available Balance",
        sortable: false,
      },
    ];
  }

  get getDate(): string {
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(new Date());
  }

  state: State = {
    showList: true,
    selectedAccount: null,
  };

  onRowClick(params: Record<string, any>) {
    this.state.selectedAccount = params.row;
    this.state.showList = false;
  }

  onShowList() {
    this.state.showList = true;
    this.state.selectedAccount = null;
  }

  _getAccountTypeColor(type: string) {
    switch (type) {
      case "checking":
        return "bg-green-200";
      default:
        return "bg-blue-200";
    }
  }

  _formattedBalance(amount: number) {
    return formatCurrency(amount);
  }
}
